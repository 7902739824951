import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import One from '../sections/one';
import Two from '../sections/two';
import Three from '../sections/three';
import Four from '../sections/four';
import Five from '../sections/five';
import Six from '../sections/six';
import Seven from '../sections/seven';
import Eight from '../sections/eight';

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Wrapper>
        <One />
        <Two />
        <Three />
        <Four />
        <Five />
        <Six />
        <Seven />
        <Eight />
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  overflow: clip;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const pageQuery = graphql`
  query {
    allCounts {
      nodes {
        gabe
        keith
        michael
        rossi
        nyc
        craniums
        alex
        playboy
        karsen
        z
        fox
        password
        matt
        nolimits
        house
        artbasel
        basel
        miami
        zhovak
        swans
        beer
        jpeg
        midnight
        nosleep
        back2back
        animalz
      }
    }
  }
`;

export default IndexPage;
